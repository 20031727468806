import React from 'react'
import {  Button,Link as LinkHref } from 'theme-ui'

const styles = {
  msg: {
    mb: 0
  },
  button: {
    display: `block`,
    mx: `auto`
  }
}

const ZoomForm = () => (
  <form >
    
      <>
        
        <Button
          type='submit'
          sx={styles.button}
        >
          <LinkHref
            variant='links.nav'
            target='_blank'
            title=''
            href='/.netlify/functions/redirectZoom'
            rel='noopener'
            sx={{textDecoration: 'none',
            color:'white'}}
           >Register
           </LinkHref>
          
        </Button>
      </>
    
  </form>
)

export default ZoomForm

