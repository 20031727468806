const thirdMonday =()=> {
  const today= new Date()
  
  // get first day of this month
  var target = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
  var found=false
  while (!found) {
    // Check to see if the 1st is on a Monday.
    var isMonday = (target.getDay() === 1);
  
    // Jump ahead three weeks from the 1st, and move back the appropriate number of days to reach the preceding Monday.
    // i.e. If the 1st is a Thursday, we would move back three days.
    // Sunday is 0, Monday is 1, and so on
    var targetDate = (target.getDay() === 0) ? 22 - (7 - 1) : 22 - (target.getDay() - 1);
   
    // Quick adjustment if the 1st is a Monday.
    if (isMonday) targetDate -= 7;
  
    // Move to the second Monday in the month.   
    target.setDate(targetDate);
  
    // Third Monday is before today's date, so find the third Monday next month.
    // January is 0, February is 1, and so on.
    // No meeting in January so fast forward to Feb
    if (((today.getDate() > target.getDate()) && (today.getMonth() === target.getMonth())) || target.getMonth()===0) {
      //return "<em>" + target.toLocaleDateString() + " is in the past...</em>";
      target.setMonth(target.getMonth() + 1);
      target.setDate(1)
    } else {
      found=true
    }
  }
  let options = { weekday: 'long',  month: 'long', day: 'numeric' };
  // Format and return string date of second Monday.
  return target.toLocaleDateString('en-AU', options);
}

export default thirdMonday
