import React from 'react'
import { Card, Text, Heading, Box } from 'theme-ui'
import ZoomForm from '@components/ZoomForm'
import thirdMonday from '@helpers/thirdMonday'

const styles = {
  card: {
    position: `relative`
  },
  wrapper: {
    maxWidth: 650,
    textAlign: `center`,
    mx: `auto`,
    py: 3
  },
  form: {
    maxWidth: 300,
    mx: `auto`,
    mt: 4
  }
}

const ZoomExpanded = ({ simple }) => {
  return (
    <Card variant='paper' sx={styles.card}>
      <Box sx={styles.wrapper}>
        <Heading variant='h2'>Register for our next Monthly Meeting!</Heading>
        <Text variant='h3'>
          {(thirdMonday())} at 4:30pm.
        </Text>
        {!simple && (
          <Text variant='p'>
            We hold monthly meetings online on the third
            Monday of the month (except January). Everyone from Fairy Bower, Little Manly, Isthmus and
            Central Manly is welcome to register. We also welcome others who wish to attend, 
            but request that you get in contact first.
          </Text>
        )}

        <Box sx={styles.form}>
        <ZoomForm />
        </Box>
      </Box>
    </Card>
  )
}

ZoomExpanded.defaultProps = {
  simple: false
}
export default ZoomExpanded
